import React from 'react';

import gradientData from "../gradient_collection_resource.json";
import {Link} from "gatsby";

const mapStateToProps = (state) => ({
  ...state,
});

class GradientButtonsGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        gradients1: [],
        image_links: ["svg_waves", "waves_violet", "waves_green", "triangle_background1", "triangle_background2", "triangle_background"],
        //image_links: [],
        showModal: false,
        gradient_body1: {},
        gradient_elems1: [],
    };
  }

  componentDidMount() {
      console.log("mounted");
    window.scrollTo(0, 0);
    let gradients = this.createGradients();
    this.setState({gradients1: gradients});
  }

    getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    displayModal(gradient) {
      this.setState({showModal: true, gradient_body1: gradient})
  }

  hideModal() {
    this.setState({showModal: false})
  }

  createGradients() {

    let gradients = [];
    for (var i = 0; i < 21; i++) {
      let ran_num = this.getRandomNumber(40, 300);
      let l = this.getRandomNumber(25, 75);
      let l1 = this.getRandomNumber(25, 75);

      let ran_num2 = this.getRandomNumber(20, 70);
      let color = `hsla(${ran_num}, 100%, ${ran_num2}%, 1)`;
      let color2 = `hsla(${ran_num}, 100%, ${this.getRandomNumber(ran_num2 + 30, 90)}%, 1)`;
      let color3 = `hsla(${ran_num}, 75%, ${l}%, 1)`;
      let color4 = `hsla(${ran_num + 100}, 75%, ${l}%, 1)`;
      let gradient_name = "Gradient" + i;
      let gradient_body = {color1: color3, color2: color4, name: gradient_name }
      gradients.push(gradient_body)
    }
    console.log("made new gradients, gradients now: ", gradients);

    return gradients;
  }

  getCSSString() {
    let css_string = `.button {
        color: #fff;
        padding: 20px;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 30px;
        display: inline-block;
        border: none;
        font-family: "Inter", sans-serif;
        background: linear-gradient(90deg, ${this.state.gradient_body1.color1}, ${this.state.gradient_body1.color2});
        background-size: 600%;        
      }
`;
  return css_string;
  }


  getHTMLString() {
    let html_string = `<button type="button" class="button">Call to Action</button>
    `;
  return html_string;
  }


  createGradientElems() {
    let gradients = this.state.gradients1;
    // if (this.state.gradients1.length == 0) {
    //   gradients = this.createGradients();
    //   console.log("creating new gradients")
    //   this.setState({gradients1: gradients})
    // }
    // else {
    //     console.log("gradients stay same else block")
    //   gradients = this.state.gradients1;
    // }


    var image_elems = gradients.map((gradient, i) => {


      return (
          <article className="flex flex-col h-full cursor-pointer" data-aos="zoom-y-out" onClick={() => this.displayModal(gradient)}>
          <div>

              <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">


                <button type="button" class="button_demo"  style={{
                background: `linear-gradient(90deg, ${gradient.color1}, ${gradient.color2})`,
                }}>Call to Action</button>
              </figure> 

          </div>

        </article>

      );
    });
    return image_elems;
  }

  updateGradients() {
      console.log("updating gradients")
    let gradients = this.createGradients();
    this.setState({gradients1: gradients});
    window.scrollTo(0, 0);

  }

  componentWillReceiveProps(nextProps) {
      console.log("updating gradients")
    if (nextProps.gradient_elems1 != this.props.gradient_elems1) {

    }
}

  render() {


    let gradient_elems1 = this.createGradientElems();
    console.log("gradient elems render: ")

    // let particle_elems = this.createParticleElems();

    // let image_elems = this.createImageElems();

    return (
        <div className="flex flex-col min-h-screen overflow-hidden bg-grey-200">                  

        {/*  Page content */}
        <main className="flex-grow">
  
          {/*  Page sections */}
          <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
              <div className="pt-8 pb-2 md:pt-14 md:pb-6">

                {/* Section header */}
                <div className="max-w-6xl mx-auto text-center pb-6 md:pb-12">
                  <h1 className="h1 mb-8 text-5xl font-extrabold leading-tight tracking-tighter" data-aos="zoom-y-out">Gradient Buttons Generator</h1>
                  <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
                    Generate gradient buttons instantly with this tool, click on a gradient to export its CSS/HTML!
                    {/* Click on a background to preview it; it will represent the exported background. */}
                  </p>
                </div>
            <div className="grid gap-4 md:gap-12 grid-cols-2 md:grid-cols-3 md:col-gap-6 md:row-gap-8 items-end ml-10">

              {/* 1st gradient */}
            {gradient_elems1}

            </div>

              </div>
            </div>

            
      <div className="max-w-6xl mx-auto px-4 sm:px-6 bg-grey-600">
        <div className="pb-2 md:pb-4">

          {/* Gradients list */}
          <div className="max-w-sm mx-auto md:max-w-none">

            {/* Gradients container */}


          </div>

          <p className="text-xl text-gray-600 text-center" data-aos="zoom-y-out" data-aos-delay="150">
                    ... want more tools like this? <Link to="/join-waitlist">Join the waitlist</Link> to be notified of when we launch more tools.
          </p>
          <button onClick={() => this.updateGradients()} type="button" class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                    More Gradients!
          </button>
        </div>
      </div>
          </section>
  
        </main>

        <div className={this.state.showModal ? "" : "hidden"}>

          <div class="fixed z-999999999 inset-0 overflow-y-auto pt-20">
            <div class="flex items-end justify-center min-h-screen pt-30 px-4 pb-20 text-center sm:block sm:p-0">

              <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>

              <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

              <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div class="sm:flex sm:items-start">

                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <button type="button" class="button_demo small_btn align-centre content-centre"  style={{
                        background: `linear-gradient(90deg, ${this.state.gradient_body1.color1}, ${this.state.gradient_body1.color2})`,
                        }}>Call to Action</button>


                      <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                        Copy gradient
                      </h3>
                      <div class="mt-2">
                        <p class="text-sm text-gray-500">
                          Copy the CSS below plus the HTML for your button.
                        </p>
                      </div>
                      <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                        CSS
                      </h3>
                      <textarea class="resize border rounded-md text-sm w-full" value={this.getCSSString()}></textarea>
                    <p className="mt-2">or</p>
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                        HTML
                    </h3>
                    <textarea class="resize border rounded-md text-sm w-full" value={this.getHTMLString()}></textarea>
                    </div>

                  </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button onClick={() => this.hideModal()} type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Done
                  </button>
                  <button onClick={() => this.hideModal()} type="button" class="mt-3 align-center content-center inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default GradientButtonsGenerator;